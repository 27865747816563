import React from 'react'
import {
  Row,
  Container,
  FancyShow
} from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import styles from './RaftLinkSection.module.scss'
import { LongArrowRight } from '@src/assets'
import cn from 'classnames'

export default ({ rafts, image }) => {
  return (
    <FancyShow>
      <Container
        className={cn(
          styles.container,
          'pb-5'
        )}
        fluid
      >

        <Row className={styles.wrapper}>
          <div style={{ backgroundImage: `url(${image})` }}
            className={styles.imageContainer}
          />
          {rafts && rafts.length > 0 &&
            rafts.map(({
              heading,
              link,
              linkTitle
            }, key) => (
              <Link to={link} key={key} className={cn(
                'col-sm-3',
                styles.raft
              )}>
                <FancyShow>
                  <h2>{heading}</h2>
                </FancyShow>

                <p
                  className='link link--big'
                >
                  {linkTitle}
                  <LongArrowRight />
                </p>
              </Link>
            ))}
        </Row>
      </Container>
    </FancyShow>
  )
}
